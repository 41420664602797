import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

export const Faq = () => (
  <Accordion type="single" collapsible className="mt-6 w-full">
    <AccordionItem value="item-1">
      <AccordionTrigger>
        What is Smart-Link and how can it benefit my business?
      </AccordionTrigger>
      <AccordionContent>
        Smart-Link is a comprehensive link management solution designed to
        streamline your online presence. It offers advanced redirect rules,
        intuitive templates, and a robust Content Delivery Network (CDN) to
        ensure your links are always optimized for speed and accuracy. By using
        Smart-Link, you can improve user experience, boost your SEO, and enhance
        your marketing campaigns with precise targeting and analytics.
      </AccordionContent>
    </AccordionItem>
    <AccordionItem value="item-2">
      <AccordionTrigger>
        How do the advanced redirect rules in Smart-Link work?
      </AccordionTrigger>
      <AccordionContent>
        Our advanced redirect rules allow you to direct users to specific pages
        based on various criteria, such as location, device type, or referral
        source. This ensures your audience receives the most relevant content,
        enhancing their overall experience and increasing your conversion rates.
        Whether you're running promotions or managing multiple campaigns, these
        redirect rules make sure your users always find what they're looking
        for.
      </AccordionContent>
    </AccordionItem>
    <AccordionItem value="item-3">
      <AccordionTrigger>
        What makes Smart-Link's templates unique?
      </AccordionTrigger>
      <AccordionContent>
        Smart-Link's templates are designed for ease of use and maximum
        customization. You can quickly set up and personalize your links to
        match your brand's look and feel, without needing any technical
        expertise. This allows you to maintain a consistent brand image across
        all your links and provides a professional appearance that builds trust
        with your audience.
      </AccordionContent>
    </AccordionItem>
  </Accordion>
);
